import { UseCMSHomepage } from "@with-nx/hooks-n-helpers";
import {
  DesignedButton,
  DesignedCard,
  ReusableSection,
  Rule,
} from "@with-nx/simple-ui/atoms";
import { VisibleContainer } from "@with-nx/simple-ui/molecules";
import { Col, Row } from "antd";
import { useRouter } from "next/router";
import { Box } from "simple-effing-primitive-layout";

const Element = ({ lighthouse = false }: { lighthouse?: boolean }) => {
  const router = useRouter();

  return (
    <ReusableSection>
      <div className="container">
        <Row gutter={[36, 36]}>
          <Col xs={24} sm={24} md={12}>
            <Rule
              parse="!hl d:block mb:12 c:?font1"
              css="--blue-gradient-text"
              tag="h4"
            >
              Let&apos;s Put On a Show!
            </Rule>
            <Rule parse="!pl d:block mb:24 c:?font2">
              At Broadway Media, we're revolutionizing the performing arts
              landscape. We innovate, create, and inspire, offering a powerful
              suite of technology tools crafted precisely for the artists of
              today.
            </Rule>
            <Box css="d:flex fw:wrap a:center" parse="mb:24">
              <DesignedButton
                label="Get Started"
                theme="white"
                properties={{ right: 12, bottom: 12 }}
                press={() =>
                  router.push(
                    `/contact/start?mode=get-started&referrer=${router.asPath}`
                  )
                }
              />
              <DesignedButton
                label="Schedule a Call"
                theme="tetriary-white"
                properties={{ right: 12, bottom: 12 }}
                href="/contact/calendar"
              />
            </Box>
          </Col>
          <Col xs={24} sm={24} md={12}>
            {lighthouse ? undefined : (
              <VisibleContainer>
                <DesignedCard
                  size="16x9"
                  embed="https://www.youtube.com/watch?v=Qx5RdK1N9KM&feature=youtu.be"
                  alt="Broadway Media Promotional Video"
                  muted={false}
                  loop={false}
                  controls={true}
                  playing={false}
                />
              </VisibleContainer>
            )}
          </Col>
        </Row>
      </div>
    </ReusableSection>
  );
};

export const HomeCommercialTemplate = ({
  lighthouse = false,
}: {
  lighthouse?: boolean;
  homepage?: UseCMSHomepage;
}) => {
  return <Element lighthouse={lighthouse} />;
};

export default HomeCommercialTemplate;
