import { UseCMSHomepage } from "@with-nx/hooks-n-helpers";
import { ReusableSection } from "@with-nx/simple-ui/atoms";
import { ReusableContainerPartners } from "@with-nx/simple-ui/organisms";

export const HomePartnersTemplate = ({
  lighthouse = false,
  homepage,
}: {
  lighthouse?: boolean;
  homepage?: UseCMSHomepage;
}) => {
  return (
    <ReusableSection>
      <div id="partners" />
      <ReusableContainerPartners
        homepage={homepage}
        lighthouse={lighthouse}
        slanted={false}
        titleTag="h6"
      />
    </ReusableSection>
  );
};

export default HomePartnersTemplate;
