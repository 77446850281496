import { Formatter, UseCMSHomepage } from "@with-nx/hooks-n-helpers";
import {
  DesignedButton,
  ReusableSection,
  Rule,
} from "@with-nx/simple-ui/atoms";
import {
  GradientHolder,
  GridStepScroller,
  ReusableDoubleGradientCard,
} from "@with-nx/simple-ui/organisms";
import { useRouter } from "next/router";
import { Box } from "simple-effing-primitive-layout";

const Element = ({
  lighthouse = false,
  homepage,
}: {
  lighthouse?: boolean;
  homepage?: UseCMSHomepage;
}) => {
  const router = useRouter();

  return (
    <ReusableSection>
      <div className="container">
        <Rule parse="!hl d:block mb:36" tag="h3">
          Explore Featured Shows
        </Rule>
        <GridStepScroller
          xs={24}
          sm={12}
          md={8}
          lg={8}
          items={[
            ...(homepage?.featureds?.map((i, ii) => {
              if (ii >= 3) {
                return null;
              }

              return (
                <GradientHolder
                  src={Formatter.image(i.image, true)}
                  key={i._id}
                  render={(colors) => (
                    <ReusableDoubleGradientCard
                      key={i._id}
                      gradients={["transparent", "transparent"]}
                      title={i.name}
                      holder={false}
                      press={() => {
                        router.push(i.url || "/search");
                      }}
                      properties={{
                        height: 384,
                        style: {
                          background: i.logoColor,
                        },
                      }}
                    >
                      <Box css="p:absolute t:0 l:0 w:100% h:100% d:flex a:center j:center">
                        {colors.ready && !lighthouse ? (
                          <img
                            loading="lazy"
                            width="176"
                            height="176"
                            src={colors.src}
                            alt={i.name || "Broadway Media"}
                          />
                        ) : undefined}
                      </Box>
                    </ReusableDoubleGradientCard>
                  )}
                />
              );
            }) || []),
          ]}
        />
        <Box css="d:flex j:flex-end" parse="mt:24">
          <DesignedButton
            size="small"
            theme="tetriary"
            label="Explore All Shows"
            href="/search"
          />
        </Box>
      </div>
    </ReusableSection>
  );
};

export const HomeFeaturedShowsTemplate = ({
  lighthouse = false,
  homepage,
}: {
  lighthouse?: boolean;
  homepage?: UseCMSHomepage;
}) => {
  return <Element homepage={homepage} lighthouse={lighthouse} />;
};

export default HomeFeaturedShowsTemplate;
