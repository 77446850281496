import { UseCMSHomepage } from "@with-nx/hooks-n-helpers";
import { ReusableSolutionsContainer } from "@with-nx/simple-ui/atoms";
import { Box } from "simple-effing-primitive-layout";

const Element = ({ lighthouse = false }: { lighthouse?: boolean }) => {
  return (
    <Box css="p:relative" parse="mt:-96">
      <div id="solutions" />
      <ReusableSolutionsContainer lighthouse={lighthouse} />
    </Box>
  );
};

export const HomeCommunityTemplate = ({
  lighthouse,
}: {
  lighthouse?: boolean;
  homepage?: UseCMSHomepage;
}) => {
  return <Element lighthouse={lighthouse} />;
};

export default HomeCommunityTemplate;
