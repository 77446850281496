import { DesignedButton, Rule } from "@with-nx/simple-ui/atoms";
import { useEffect, useRef, useState } from "react";
import { Box } from "simple-effing-primitive-layout";

const defaultTransition = "transform 1s ease-out";

export const HomeSolutions = ({
  mobile,
  tablet,
}: {
  mobile: boolean;
  tablet: boolean;
}) => {
  const [visibleIndexes, _visibleIndexes] = useState<number[]>([]);
  const solutionRefs = useRef<(HTMLDivElement | null)[]>([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const target = entry.target as HTMLElement;
          const index = parseInt(target?.dataset?.["index"] || "0");

          if (entry.isIntersecting) {
            _visibleIndexes((prev) => [...new Set([...prev, index])]);
          } else {
            _visibleIndexes((prev) => prev.filter((i) => i !== index));
          }
        });
      },
      { threshold: 0.8 }
    );

    solutionRefs.current.forEach((ref) => {
      if (ref) observer.observe(ref);
    });

    return () => {
      solutionRefs.current.forEach((ref) => {
        if (ref) observer.unobserve(ref);
      });
    };
  }, []);

  const imgSizeMobile = (scale?: number) =>
    mobile ? `scale(${scale || 0.6})` : "";

  const solutions = [
    {
      id: "scenery-solution",
      title: "Bring your stage to life",
      subtitle: "with digital scenery",
      description:
        "Say goodbye to bulky sets & challenging scene changes - Scenic Projections provide beautifully animated, script-accurate digital projection rentals for licensed show titles. Looking to craft the perfect stage? Peruse our library of 3,000+ digital backdrops.",
      button: "Explore Digital Scenery",
      href: "/scenic-projections",
      renderImages: (index: number) => {
        const visible = visibleIndexes.includes(index);

        const tabletStyle = tablet
          ? {
              margin: "auto",
              maxWidth: "60%",
              maxHeight: "60%",
              transform: "translateY(40%)",
            }
          : {};

        return (
          <Box
            parse="p:relative d:flex a:center j:center h:100% w:100% oa:visible"
            style={{
              ...tabletStyle,
            }}
          >
            {/* CENTER */}
            <img
              width="383px"
              height="215px"
              src="/assets/home/animations/scenery.png"
              alt="digital scenery"
              loading="lazy"
              style={{
                zIndex: 3,
                transform: `${imgSizeMobile(0.6)} translateY(${
                  visible ? "-50px" : "0"
                })`,
                transition: defaultTransition,
              }}
            />
            {/* TOP LEFT */}
            <img
              src="/assets/home/animations/scenery_1.png"
              loading="lazy"
              alt="digital scenery"
              style={{
                zIndex: 2,
                position: "absolute",
                top: mobile ? -10 : "-20px",
                left: 0,
                transform: `${imgSizeMobile()} translateY(${
                  visible ? "45px" : "0"
                })`,
                transition: defaultTransition,
              }}
            />
            {/* SECOND TOP LEFT */}
            <img
              src="/assets/home/animations/scenery_2.png"
              alt="digital scenery"
              loading="lazy"
              style={{
                zIndex: 1,
                position: "absolute",
                top: -65,
                left: "calc(20%)",
                transform: `${imgSizeMobile()} translateY(${
                  visible ? "-40px" : "0"
                })`,
                transition: defaultTransition,
              }}
            />
            {/* TOP RIGHT */}
            <img
              src="/assets/home/animations/scenery_4.png"
              alt="digital scenery"
              loading="lazy"
              style={{
                zIndex: 1,
                position: "absolute",
                top: -70,
                right: "0",
                transform: `${imgSizeMobile()} translateY(${
                  visible ? "50px" : "0"
                })`,
                transition: defaultTransition,
              }}
            />

            {/* BOTTOM LEFT */}
            <img
              src="/assets/home/animations/scenery_3.png"
              alt="digital scenery"
              loading="lazy"
              style={{
                zIndex: 1,
                position: "absolute",
                bottom: -45,
                left: mobile ? "-6%" : "8%",
                transform: `${imgSizeMobile()} translateY(${
                  visible ? "-40px" : "0"
                })`,
                transition: defaultTransition,
              }}
            />

            {/* BOTTOM RIGHT */}
            <img
              src="/assets/home/animations/scenery_5.png"
              alt="digital scenery"
              loading="lazy"
              style={{
                zIndex: 1,
                position: "absolute",
                bottom: -15,
                right: mobile ? "-8%" : "10%",
                transform: `${imgSizeMobile()} translateY(${
                  visible ? "-40px" : "0"
                })`,
                transition: defaultTransition,
              }}
            />
          </Box>
        );
      },
    },
    {
      id: "gear-solution",
      title: "Projectors & Microphones",
      subtitle: "made for the stage",
      description:
        "Experience theatrical hardware excellence with StageGear rentals, carefully crafted to upgrade your production. Our cutting-edge Stage Projectors ensure expansive, bright, & shadow-free visuals, while our BodyMics wireless microphone system rentals set the stage for unparalleled sound.",
      button: "Get the Gear",
      href: "/stage-gear",
      renderImages: (index: number) => {
        const visible = visibleIndexes.includes(index);

        const tabletStyle = tablet
          ? {
              margin: "auto",
              maxWidth: "60%",
              maxHeight: "60%",
              transform: "translateY(40%)",
            }
          : {};

        const mobileStyle = mobile
          ? {
              transform: "scale(1)",
            }
          : {};

        return (
          <Box
            parse="p:relative d:flex a:center j:center h:100% w:100% oa:visible"
            style={{
              ...tabletStyle,
              ...mobileStyle,
            }}
          >
            <div
              style={{
                width: "80%",
                height: "80%",
                position: "absolute",
                top: "-10",
                left: "0",
                background: "#0D306F",
                filter: "blur(100px)",
                borderRadius: "9999px",
                zIndex: 1,
              }}
            />
            <img
              width="256px"
              height="199px"
              src="/assets/home/animations/gear.png"
              alt="projector"
              loading="lazy"
              style={{
                zIndex: 3,
                position: "absolute",
                left: mobile ? "-10%" : 0,
                bottom: 0,
                transform: `${imgSizeMobile()} translateY(${
                  visible ? "20px" : "0"
                })`,
                transition: defaultTransition,
              }}
            />
            <img
              src="/assets/home/animations/gear_1.png"
              alt="microphone system"
              loading="lazy"
              style={{
                zIndex: 2,
                position: "absolute",
                left: mobile ? "20%" : "30%",
                bottom: mobile ? "4%" : 65,
                transform: `${imgSizeMobile(0.7)} translateY(${
                  visible ? "-20px" : "0"
                })`,
                transition: defaultTransition,
              }}
            />
            <img
              src="/assets/home/animations/gear_2.png"
              alt="microphone"
              loading="lazy"
              style={{
                zIndex: 2,
                position: "absolute",
                top: mobile ? "30%" : "52%",
                right: mobile ? "13%" : "18%",
                transform: `${imgSizeMobile()} translateY(${
                  visible ? "-20px" : "0"
                })`,
                transition: defaultTransition,
              }}
            />
            <img
              src="/assets/home/animations/gear_3.png"
              alt="microphone"
              loading="lazy"
              style={{
                zIndex: 1,
                position: "absolute",
                top: mobile ? "40%" : "64%",
                right: mobile ? "0" : "6%",
                transform: `${imgSizeMobile()}translateY(${
                  visible ? "20px" : "0"
                })`,
                transition: defaultTransition,
              }}
            />
          </Box>
        );
      },
    },
    {
      id: "choreo-solution",
      title: "Stay in Step",
      subtitle: "with Broadway's Best",
      description:
        "Choreography Guides let you access Broadway-level choreography via easy-to-use, digital video guides. Developed by Tony-award winning director/choreographer Jerry Mitchell, and veteran Broadway performer Paul Canaan, this resource takes the guesswork out of choreographing a musical for all skill levels.",
      button: "Let's Dance",
      href: "/choreography-guides",
      renderImages: (index: number) => {
        const visible = visibleIndexes.includes(index);

        const tabletStyle = tablet
          ? {
              margin: "auto",
              maxWidth: "60%",
              maxHeight: "60%",
            }
          : {};

        return (
          <Box
            parse={`p:relative mh:358`}
            style={{
              ...tabletStyle,
            }}
          >
            <img
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                transform: `${imgSizeMobile(0.8)} translateY(${
                  visible ? "-30px" : "0"
                })`,
                transition: defaultTransition,
              }}
              src="/assets/home/animations/choreo.png"
              alt="people doing choreography"
              loading="lazy"
            />

            <img
              style={{
                position: "absolute",
                bottom: mobile ? "5%" : -20,
                left: mobile ? "-10%" : 40,
                transform: `${imgSizeMobile(0.6)} translateY(${
                  visible ? "15px" : "0"
                })`,
                transition: defaultTransition,
              }}
              src="/assets/home/animations/choreo_1.png"
              alt="people doing choreography"
              loading="lazy"
            />
          </Box>
        );
      },
    },
  ];

  const mobileOrTablet = mobile || tablet;

  return (
    <Box parse="mt:104">
      <div className="container">
        <Rule parse="d:block ta:center !hxl c:var(--gold-100) mb:100">
          Our Solutions
        </Rule>

        {solutions.map((solution, index) => {
          const last = index === solutions.length - 1;

          return (
            <div
              ref={(el) => (solutionRefs.current[index] = el)}
              data-index={index}
              id={solution.id}
              key={solution.id}
            >
              <Box
                parse="pt:50 pb:50"
                style={{
                  display: "grid",
                  gridTemplateColumns: mobileOrTablet ? "1fr" : "1fr 1fr",
                  marginBottom: !last ? "60px" : "0",
                  columnGap: "104px",
                  textAlign: tablet ? "center" : "left",
                }}
              >
                <Box>
                  <Rule parse={`!_${mobileOrTablet ? "h2" : "h1"}`}>
                    {solution.title}
                  </Rule>
                  <Rule
                    parse={`!_${mobileOrTablet ? "h3" : "h2"} d:block mb:20`}
                    weight={700}
                  >
                    {solution.subtitle}
                  </Rule>
                  <Rule parse="!bxl d:block mb:20">{solution.description}</Rule>
                  <DesignedButton
                    icon="circle_arrow_right"
                    label={solution.button}
                    href={solution.href}
                    theme="tetriary-white"
                    properties={
                      mobileOrTablet
                        ? {
                            width: "100%",
                            display: "flex",
                            align: "center",
                            justify: "center",
                            style: {
                              justifyContent: "center",
                              width: "100%",
                            },
                          }
                        : {}
                    }
                    container={{
                      style: {
                        width: mobileOrTablet ? "100%" : "auto",
                        display: "block",
                      },
                    }}
                  />
                </Box>

                <Box
                  parse={`mh:${tablet ? "450" : mobile ? "200" : "300"} ${
                    mobile ? "mb:30 w:100%" : ""
                  }`}
                  style={{
                    order: index % 2 !== 0 || mobileOrTablet ? -1 : 1,
                  }}
                >
                  {solution.renderImages(index)}
                </Box>
              </Box>
            </div>
          );
        })}
      </div>
    </Box>
  );
};
